<template>
  <v-row no-gutters>
    <v-col cols="3">
      <label v-if="!noLabel" class="text-capitalize font-weight-bold">{{
        label
      }}</label>
    </v-col>
    <v-col cols="9">
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FormLayout1',
  props: {
    label: {
      type: String,
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style></style>
