const newsAndEventsMixin = {
  methods: {
    checkUrlProtocol(link) {
      let newLink = link

      if (link.substr(0, 4) != 'http') {
        newLink = 'https://' + link
      }

      return newLink
    }
  }
}

export default newsAndEventsMixin
