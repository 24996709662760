import commonMixin from '@/mixins/common.mixin'
import { i18nHelper } from '@/utils'

const crudMixin = {
  mixins: [commonMixin],
  computed: {
    createRecordComplete() {
      let obj = this.actions.createRecord

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete
      } else {
        return false
      }
    },
    readRecordComplete() {
      let obj = this.actions.readRecord

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete
      } else {
        return false
      }
    },
    updateRecordComplete() {
      let obj = this.actions.updateRecord

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete
      } else {
        return false
      }
    },
    deleteRecordComplete() {
      let obj = this.actions.deleteRecord

      if (obj) {
        return this.$store.state[obj.module][obj.state].complete
      } else {
        return false
      }
    }
  },
  watch: {
    createRecordComplete() {
      let obj = this.actions.createRecord
      let response = this.$store.state[obj.module][obj.state]
      let successAction = obj.successAction

      if (response) {
        if (response.complete) {
          this.createRecordCompleteAction(response, successAction)
        }
      }
    },
    readRecordComplete() {
      let obj = this.actions.readRecord
      let response = this.$store.state[obj.module][obj.state]
      let successAction = obj.successAction

      if (response) {
        if (response.complete) {
          this.readRecordCompleteAction(response, successAction)
        }
      }
    },
    updateRecordComplete() {
      let obj = this.actions.updateRecord
      let response = this.$store.state[obj.module][obj.state]
      let successAction = obj.successAction

      if (response) {
        if (response.complete) {
          this.updateRecordCompleteAction(response, successAction)
        }
      }
    },
    deleteRecordComplete() {
      let obj = this.actions.deleteRecord
      let response = this.$store.state[obj.module][obj.state]
      let successAction = obj.successAction

      if (response) {
        if (response.complete) {
          this.deleteRecordCompleteAction(response, successAction)
        }
      }
    }
  },
  methods: {
    createRecordCompleteAction(response, successAction) {
      let title = this.actions.createRecord.title
      let message = i18nHelper.getMessage('message.addRecordSuccessMessage')
      let addOnOkAction = successAction

      if (response.code == 0) {
        this.openSuccessAppDialogInfo(title, message, addOnOkAction)
      } else {
        this.openErrorAppDialogInfo(title, response)
      }
      this.initialCreateRecordState()
    },
    readRecordCompleteAction(response, successAction) {
      let title = this.actions.readRecord.title

      if (response.code == 0) {
        successAction(response)
      } else {
        this.openErrorAppDialogInfo(title, response)
      }
      this.initialReadRecordState()
    },
    updateRecordCompleteAction(response, successAction) {
      let title = this.actions.updateRecord.title
      let message = i18nHelper.getMessage('message.updateRecordSuccessMessage')
      let addOnOkAction = successAction

      if (response.code == 0) {
        this.openSuccessAppDialogInfo(title, message, addOnOkAction)
      } else {
        this.openErrorAppDialogInfo(title, response)
      }
      this.initialUpdateRecordState()
    },
    deleteRecordCompleteAction(response, successAction) {
      let title = this.actions.deleteRecord.title
      let message = i18nHelper.getMessage('message.deleteRecordSuccessMessage')
      let addOnOkAction = successAction

      if (response.code == 0) {
        this.openSuccessAppDialogInfo(title, message, addOnOkAction)
      } else {
        this.openErrorAppDialogInfo(title, response)
      }
      this.initialDeleteRecordState()
    },
    requestCreate(data) {
      let type = 'info'
      let title = this.actions.createRecord.title
      let description = [i18nHelper.getMessage('message.requestAddMessage')]
      let buttons = []

      buttons.push({
        text: i18nHelper.getMessage('text.cancel'),
        outlined: true,
        action: () => {
          this.closeAppDialogInfo()
        }
      })

      buttons.push({
        text: i18nHelper.getMessage('text.confirm'),
        color: 'primary',
        action: () => {
          this.createRecord(data)
          this.closeAppDialogInfo()
        }
      })

      this.openAppDialogInfo(type, title, description, buttons)
    },
    requestUpdate(id, data) {
      let type = 'info'
      let title = this.actions.updateRecord.title
      let description = [i18nHelper.getMessage('message.requestUpdateMessage')]
      let buttons = []

      buttons.push({
        text: i18nHelper.getMessage('text.cancel'),
        outlined: true,
        action: () => {
          this.closeAppDialogInfo()
        }
      })

      buttons.push({
        text: i18nHelper.getMessage('text.confirm'),
        color: 'primary',
        action: () => {
          this.updateRecord(id, data)
          this.closeAppDialogInfo()
        }
      })

      this.openAppDialogInfo(type, title, description, buttons)
    },
    requestDelete(id) {
      let type = 'warning'
      let title = this.actions.deleteRecord.title
      let description = [i18nHelper.getMessage('message.requestDeleteMessage')]
      let buttons = []

      buttons.push({
        text: i18nHelper.getMessage('text.cancel'),
        outlined: true,
        action: () => {
          this.closeAppDialogInfo()
        }
      })

      buttons.push({
        text: i18nHelper.getMessage('text.confirm'),
        color: 'primary',
        action: () => {
          this.deleteRecord(id)
          this.closeAppDialogInfo()
        }
      })

      this.openAppDialogInfo(type, title, description, buttons)
    },
    createRecord(data) {
      this.$store.dispatch(this.actions.createRecord.action, { data })
    },
    readRecord(id) {
      this.$store.dispatch(this.actions.readRecord.action, { id })
    },
    updateRecord(id, data) {
      this.$store.dispatch(this.actions.updateRecord.action, { id, data })
    },
    deleteRecord(id) {
      this.$store.dispatch(this.actions.deleteRecord.action, { id })
    },
    initialCreateRecordState() {
      this.$store.dispatch(this.actions.initialCreateRecordState)
    },
    initialReadRecordState() {
      this.$store.dispatch(this.actions.initialReadRecordState)
    },
    initialUpdateRecordState() {
      this.$store.dispatch(this.actions.initialUpdateRecordState)
    },
    initialDeleteRecordState() {
      this.$store.dispatch(this.actions.initialDeleteRecordState)
    }
  }
}

export default crudMixin
