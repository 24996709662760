<template>
  <div class="page-container">
    <AppChildPageHeader
      :title="pageTitle"
      :breadcrumbs="breadcrumbs"
    ></AppChildPageHeader>

    <v-card class="page-card-wrapper">
      <v-row no-gutters justify="center">
        <v-col cols="8">
          <FormLayout1 :noLabel="true">
            <v-card-title class="px-0 mb-2 text-capitalize font-weight-regular">
              <v-icon class="mr-2">mdi-cube</v-icon>
              {{ $t('text.basicInformation') }}
            </v-card-title>
          </FormLayout1>
          <v-form ref="newsAndEventsDetailsForm">
            <template
              v-for="(item, index) in formFields.filter(
                (x) => x.key != 'image'
              )"
            >
              <FormLayout1
                :key="index"
                :label="$t(`text.${item.label}`)"
                class="pt-3"
              >
                <AppFormField
                  ref="formField"
                  v-model="item.model"
                  :outlined="true"
                  :dense="true"
                  :type="item.type"
                  :items="item.items"
                  :prefix="item.prefix"
                  :suffix="item.suffix"
                  :range="item.range"
                  :maxDate="item.maxDate"
                  :rules="
                    !item.optional ? item.rules : item.model ? item.rules : []
                  "
                  :keyProps="item.key"
                  @upload-file="uploadFile"
                ></AppFormField>
              </FormLayout1>
            </template>
          </v-form>

          <FormLayout1 :noLabel="true" class="mt-6">
            <v-btn
              depressed
              outlined
              class="text-caption mr-3"
              @click="resetForm"
              >{{ $t('text.reset') }}</v-btn
            >
            <v-btn
              depressed
              color="primary"
              class="text-caption"
              @click="saveChanges"
              >{{ $t('text.saveChanges') }}</v-btn
            >
          </FormLayout1>

          <v-divider class="my-8"></v-divider>

          <FormLayout1 :noLabel="true">
            <v-card-title class="px-0 mb-2 text-capitalize font-weight-regular">
              <v-icon class="mr-2">mdi-image</v-icon>
              {{ $t('text.image') }}
            </v-card-title>
          </FormLayout1>

          <v-form ref="newsAndEventsImageForm">
            <template
              v-for="(item, index) in formFields.filter(
                (x) => x.key == 'image'
              )"
            >
              <FormLayout1 :key="index" :label="$t(`text.${item.label}`)">
                <AppFormField
                  ref="formField"
                  v-model="item.model"
                  :outlined="true"
                  :dense="true"
                  :type="item.type"
                  :items="item.items"
                  :prefix="item.prefix"
                  :suffix="item.suffix"
                  :rules="
                    !item.optional ? item.rules : item.model ? item.rules : []
                  "
                  :keyProps="item.key"
                  @upload-file="uploadFile"
                  class="pt-3"
                ></AppFormField>
              </FormLayout1>
            </template>
          </v-form>
        </v-col>
      </v-row>

      <v-divider class="my-8"></v-divider>

      <v-row no-gutters justify="center">
        <v-col cols="6">
          <template v-for="(i, index) in dataFields">
            <FormLayout1 :key="index" :label="$t(`text.${i}`)" class="mb-4">
              <div class="text-body-1">{{ newsAndEventsObj[i] }}</div>
            </FormLayout1>
          </template>
        </v-col>
      </v-row>

      <template>
        <v-divider class="my-8"></v-divider>

        <div class="d-flex justify-center">
          <v-btn
            min-width="300"
            depressed
            color="error"
            class="text-caption"
            @click="requestDelete(id)"
            >{{ $t('text.deleteRecord') }}</v-btn
          >
        </div>
      </template>
    </v-card>
  </div>
</template>

<script>
import commonMixin from '@/mixins/common.mixin'
import crudMixin from '@/mixins/crud.mixin'
import newsAndEventsMixin from '@/mixins/newsAndEvents.mixin'

import FormLayout1 from '@/components/form/FormLayout-1'
import { ROUTE_NAME, FORM_FIELD_TYPE } from '@/constants'
import { formValidationHelper, i18nHelper } from '@/utils'
import {
  NEWS_AND_EVENTS_GET_NEWS_AND_EVENTS,
  NEWS_AND_EVENTS_UPDATE_NEWS_AND_EVENTS,
  NEWS_AND_EVENTS_DELETE_NEWS_AND_EVENTS,
  NEWS_AND_EVENTS_UPLOAD_NEWS_AND_EVENTS_IMAGE,
  NEWS_AND_EVENTS_INITIAL_GET_NEWS_AND_EVENTS_STATE,
  NEWS_AND_EVENTS_INITIAL_UPDATE_NEWS_AND_EVENTS_STATE,
  NEWS_AND_EVENTS_INITIAL_DELETE_NEWS_AND_EVENTS_STATE,
  NEWS_AND_EVENTS_INITIAL_UPLOAD_NEWS_AND_EVENTS_IMAGE_STATE
} from '@/store/news-and-events.module'

export default {
  name: 'NewsAndEventsDetails',
  components: {
    FormLayout1
  },
  mixins: [commonMixin, crudMixin, newsAndEventsMixin],
  data: () => ({
    id: null,
    validation: formValidationHelper,
    pageTitle: 'newsAndEventsDetails',
    breadcrumbs: [
      {
        text: 'home',
        disabled: false,
        to: { name: ROUTE_NAME.HOME }
      },
      {
        text: 'newsAndEvents',
        disabled: false,
        to: { name: ROUTE_NAME.NEWS_AND_EVENTS_LIST }
      },
      {
        text: 'details',
        disabled: true
      }
    ],
    newsAndEventsObj: {},
    formFields: [
      {
        model: '',
        label: 'title',
        key: 'title',
        rules: formValidationHelper.requiredRules(),
        lg: 6,
        cols: 12
      },
      {
        model: '',
        label: 'link',
        key: 'link',
        rules: formValidationHelper.urlRules(),
        lg: 6,
        cols: 12,
        optional: true
      },
      {
        model: '',
        label: 'description',
        key: 'description',
        rules: formValidationHelper.requiredRules(),
        lg: 12,
        cols: 12,
        type: FORM_FIELD_TYPE.RICH_TEXT,
        rows: 2
      },
      {
        model: '',
        label: 'image',
        key: 'image',
        type: FORM_FIELD_TYPE.IMAGE_FILE_INPUT,
        lg: 9,
        cols: 12
      }
    ],
    dataFields: ['createdAt', 'updatedAt'],
    actions: {
      readRecord: {
        module: 'newsAndEvents',
        state: 'newsAndEvents',
        action: NEWS_AND_EVENTS_GET_NEWS_AND_EVENTS,
        title: i18nHelper.getMessage('text.getNewsAndEvents'),
        successAction: () => {}
      },
      initialReadRecordState: NEWS_AND_EVENTS_INITIAL_GET_NEWS_AND_EVENTS_STATE,
      updateRecord: {
        module: 'newsAndEvents',
        state: 'updateNewsAndEvents',
        action: NEWS_AND_EVENTS_UPDATE_NEWS_AND_EVENTS,
        title: i18nHelper.getMessage('text.updateNewsAndEvents'),
        successAction: () => {}
      },
      initialUpdateRecordState:
        NEWS_AND_EVENTS_INITIAL_UPDATE_NEWS_AND_EVENTS_STATE,
      deleteRecord: {
        module: 'newsAndEvents',
        state: 'deleteNewsAndEvents',
        action: NEWS_AND_EVENTS_DELETE_NEWS_AND_EVENTS,
        title: i18nHelper.getMessage('text.deleteNewsAndEvents'),
        successAction: () => {}
      },
      initialDeleteRecordState:
        NEWS_AND_EVENTS_INITIAL_DELETE_NEWS_AND_EVENTS_STATE
    }
  }),
  computed: {
    uploadNewsAndEventsImageComplete() {
      return this.$store.state.newsAndEvents.uploadNewsAndEventsImage.complete
    }
  },
  watch: {
    uploadNewsAndEventsImageComplete() {
      let response = this.$store.state.newsAndEvents.uploadNewsAndEventsImage

      if (response.complete) {
        this.uploadNewsAndEventsImageCompleteAction(response)
      }
    }
  },
  created() {
    this.initNewsAndEventsDetails()
  },
  methods: {
    uploadNewsAndEventsImageCompleteAction(response) {
      let title = i18nHelper.getMessage('text.uploadNewsAndEventsImage')
      let message = i18nHelper.getMessage(
        'message.uploadNewsAndEventsImageSuccessMessage'
      )
      let addOnOkAction = () => {
        this.initNewsAndEventsDetails()
      }

      if (response.code == 0) {
        this.openSuccessAppDialogInfo(title, message, addOnOkAction)
      } else {
        this.openErrorAppDialogInfo(title, response)
      }
      this.initialNewsAndEventsImageState()
    },
    uploadFile(file) {
      let formData = new FormData()
      formData.append('image', file)

      this.uploadNewsAndEventsImage(this.id, formData)
    },
    uploadNewsAndEventsImage(id, formData) {
      this.$store.dispatch(NEWS_AND_EVENTS_UPLOAD_NEWS_AND_EVENTS_IMAGE, {
        id,
        formData
      })
    },
    initialNewsAndEventsImageState() {
      this.$store.dispatch(
        NEWS_AND_EVENTS_INITIAL_UPLOAD_NEWS_AND_EVENTS_IMAGE_STATE
      )
    },
    saveChanges() {
      if (this.$refs.newsAndEventsDetailsForm.validate()) {
        let data = this.mapArrayToObjectData(this.formFields)

        data.link = data.link ? this.checkUrlProtocol(data.link) : null

        this.requestUpdate(this.id, data)
      }
    },
    resetForm() {
      this.$refs.newsAndEventsDetailsForm.reset()
      this.readRecord(this.id)
    },
    importNewsAndEventsData(response) {
      let data = response.data

      this.formFields.forEach((x) => {
        this.formFields.find((y) => y.key == x.key).model = data[x.key]
      })
      this.newsAndEventsObj = data
    },
    importSuccessAction() {
      this.actions.readRecord.successAction = (response) => {
        this.importNewsAndEventsData(response)
      }

      this.actions.updateRecord.successAction = () => {
        this.resetForm()
      }

      this.actions.deleteRecord.successAction = () => {
        this.backToParentPage(ROUTE_NAME.NEWS_AND_EVENTS)
      }
    },
    initNewsAndEventsDetails() {
      let id = this.$route.params.id

      this.id = id
      this.readRecord(id)
      this.importSuccessAction()
    }
  }
}
</script>

<style></style>
